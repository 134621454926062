const loadScript = ({ src, type = 'module', append = 'body', callback = null, async = false, defer = false, isCss = false }) => {
  if (isCss) {
    const link = document.createElement('link')
    link.rel = 'stylesheet'
    link.href = src
    if (append === 'body') document.body.appendChild(link)
    else document.head.appendChild(link)
  } else {
    const script = document.createElement('script')
    script.type = type || 'text/javascript'
    script.src = src
    if (async === true) script.async = true
    if (defer === true) script.defer = true
    if (callback) script.onload = callback
    if (append === 'body') document.body.appendChild(script)
    else document.head.appendChild(script)
  }
}

export default loadScript
