import dynamic from 'next/dynamic'
import { useEffect, useState } from 'react'
import loadScript from './load-script'
import { useRouter } from 'next/router'

const SetDYContext = ({ type = '', data = '' }) => {
  const { locale } = useRouter()
  const [loadDYScripts, setLoadDYScripts] = useState(false)

  const dynamicYieldId = process.env.NEXT_PUBLIC_DYNAMIC_YIELD_ID
  const language = /en/i.test(locale) ? 'en_CA' : 'fr_CA'

  useEffect(() => {
    window.DY = window.DY || {}
    DY.recommendationContext = { type: type, lng: language, ...(!!data ? { data: data } : {}) }
    DY.userActiveConsent = { accepted: true }
    setLoadDYScripts(true)
  }, [data])

  useEffect(() => {
    if (loadDYScripts) {
      const dynamicScriptSrc = `//cdn.dynamicyield.com/api/${dynamicYieldId}/api_dynamic.js`
      const staticScriptSrc = `//cdn.dynamicyield.com/api/${dynamicYieldId}/api_static.js`

      loadScript({
        src: dynamicScriptSrc,
        type: 'text/javascript',
        async: true,
        append: 'head',
      })

      loadScript({
        src: staticScriptSrc,
        type: 'text/javascript',
        async: true,
        append: 'head',
      })
    }

    return () => {
      const scriptTagApiDynamic = document.querySelector(`script[src="//cdn.dynamicyield.com/api/${dynamicYieldId}/api_dynamic.js"]`)
      if (scriptTagApiDynamic) scriptTagApiDynamic.remove()

      const scriptTagApiStatic = document.querySelector(`script[src="//cdn.dynamicyield.com/api/${dynamicYieldId}/api_static.js"]`)
      if (scriptTagApiStatic) scriptTagApiStatic.remove()

      setLoadDYScripts(false)
    }
  }, [loadDYScripts])

  return null
}

export default dynamic(() => Promise.resolve(SetDYContext), {
  ssr: false,
})
